import { getAppVersion } from '@/api';
import { getCookie } from '@/utils/auth';
export default {
  created() {
    if (getCookie("token")) {
      this.$store.dispatch("getUserInfo");
    }
    if (process.env.VUE_APP_TYPE == 'app') {
      getAppVersion({
        version: process.env.VUE_APP_VERSION
      }).then(res => {
        this.is_update = res.data.is_update;
        this.url = res.data.url;
      });
    }
    this.$store.dispatch("getHeadItems");
  },
  data() {
    return {
      is_update: false,
      url: ""
    };
  },
  methods: {
    openDown() {
      window.open(this.url, '_blank');
    },
    onCopySuccess() {
      this.$copyText(this.url).then(() => {
        alert('复制成功！');
      }, () => {
        alert('复制失败！');
      });
    }
  }
};