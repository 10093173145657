import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

const routes = [
	{
		name: 'notFound',
		path: '/',
		redirect: {
			name: 'index',
		},
	},
	{
		name: 'index',
		path: '/index',
		component: () => import('./views/index'),
		query: { tabbar: 1 },
		meta: {
			title: '首页',
			tabbar: 0,
			roles: false  //是否需要登录才可以访问
		}
	},
	{
		name: 'indexDetailed',
		path: '/index_detailed',
		component: () => import('./views/index/indexDetailed'),
		query: { tabbar: 1 },
		meta: {
			title: '首页详情',
			tabbar: 0,
			roles: false  //是否需要登录才可以访问
		}
	},
	{
		name: 'dividend',
		path: '/dividend',
		component: () => import('./views/dividend'),
		query: { tabbar: 1 },
		meta: {
			title: '海南红利',
			tabbar: 1,
			roles: true  //是否需要登录才可以访问
		}
	},
	{
		name: 'projects',
		path: '/projects',
		component: () => import('./views/projects'),
		query: { tabbar: 1 },
		meta: {
			title: '封关创作',
			tabbar: 2,
			roles: false  //是否需要登录才可以访问
		}
	},
	{
		name: 'project-details',
		path: '/project-details',
		component: () => import('./views/projects/project-details.vue'),
		query: { tabbar: 1 },
		meta: {
			title: '封关创作详情',
			tabbar: 2,
			roles: true  //是否需要登录才可以访问
		}
	},
	{
		name: 'user',
		path: '/user_index',
		component: () => import('./views/user_index'),
		query: { tabbar: 1 },
		meta: {
			title: '个人中心',
			tabbar: 3,
			roles: true  //是否需要登录才可以访问
		}
	},
	{
		name: 'recharge',
		path: '/recharge',
		component: () => import('./views/recharge'),
		query: { tabbar: 1 },
		meta: {
			title: '充值',
			tabbar: 3,
			roles: true  //是否需要登录才可以访问
		}
	},
	{
		name: 'withdrawal',
		path: '/withdrawal',
		component: () => import('./views/withdrawal'),
		query: { tabbar: 1 },
		meta: {
			title: '提现',
			tabbar: 3,
			roles: true  //是否需要登录才可以访问
		}
	},
	{
		name: 'transfer',
		path: '/transfer',
		component: () => import('./views/transfer'),
		meta: {
			title: '数字划转',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'investmentRecords',
		path: '/investment-records',
		component: () => import('./views/investmentRecords'),
		meta: {
			title: '投资记录',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'fundList', //资金明细
		path: '/fund-list',
		component: () => import('./views/fundList'),
		meta: {
			title: '资金明细',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'rechargeList',
		path: '/recharge_list',
		component: () => import('./views/rechargeList'),
		meta: {
			title: '充值记录',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'cashList',
		path: '/cash_list',
		component: () => import('./views/cashList'),
		meta: {
			title: '提现记录',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'changePwd',
		path: '/change-pwd',
		component: () => import('./views/changePwd'),
		meta: {
			title: '修改密码',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'authentication',
		path: '/authentication',
		component: () => import('./views/authentication'),
		meta: {
			title: '实名认证',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'myTeam',
		path: '/my-team',
		component: () => import('./views/myTeam'),
		meta: {
			title: '我的团队',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问

		},
	},
	{
		name: 'inviteFriends',
		path: '/invite-friends',
		component: () => import('./views/inviteFriends'),
		meta: {
			title: '邀请好友',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问

		},
	},
	{
		name: 'bankCards',
		path: '/bank-cards',
		component: () => import('./views/bankCards'),
		meta: {
			title: '银行卡',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'addCards',
		path: '/add-cards',
		component: () => import('./views/bankCards/addbank.vue'),
		meta: {
			title: '银行卡',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'trusteeship',
		path: '/trusteeship',
		component: () => import('./views/trusteeship'),
		meta: {
			title: '托管资产',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'offshoreAssets',
		path: '/offshore-assets',
		component: () => import('./views/offshoreAssets'),
		meta: {
			title: '我的离岸资产',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	}, {
		name: 'offshoreAssetsPay',
		path: '/offshore-assets-pay',
		component: () => import('./views/offshoreAssets/offshoreAssetsPay.vue'),
		meta: {
			title: '激活离岸资产',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'transferMoney',
		path: '/transfer-money',
		component: () => import('./views/transferMoney'),
		meta: {
			title: '资金划转',
			tabbar: 3, //是否显示底部导航栏
			roles: true  //是否需要登录才可以访问
		},
	},
	{
		name: 'login',
		path: '/login',
		component: () => import('./views/login'),
		meta: {
			title: '登录',
			tabbar: 3, //是否显示底部导航栏
			roles: false  //是否需要登录才可以访问
		},
	}
];


let routerType = process.env.VUE_APP_TYPE == 'app';
const router = createRouter({
	routes,
	// history: createWebHistory(),
	history: routerType ? createWebHashHistory() : createWebHistory()
	// history:createWebHashHistory()

});

// router.beforeEach((to, from, next) => {
// 	const title = to.meta && to.meta.title;
// 	if (title) {
// 		document.title = title;
// 	}
// 	next();
// });

export { router };
