import request from '@/utils/request'
import { getCookie } from '../utils/auth'



// 注册
export function register (data) {
	// phone pwd pwd2 top verify_code
	return request({
		url: '/Mobile/reg',
		method: 'post',
		data
	})
}

// 获取验证码
export function getRegusterCode () {
	return request({
		url: '/Handle/checkcode',
		method: 'get'
	})
}

// 登录
export function goLogin (data) {
	//account password 
	return request({
		url: '/Mobile/login',
		method: 'post',
		// headers,
		data
	})
}

// 首页公告
export function gongGao () {
	return request({
		url: '/Mobile/gonggao',
		method: 'get'
	})
}

// 获取投资分类
export function listNav () {
	return request({
		url: "/Mobile/list_nav",
		method: 'get'
	})
}

// 获取当前分类项目列表
export function listNavId (urlParams) {
	return request({
		url: "/Mobile/lists?class_id=" + urlParams,
		method: 'get'
	})
}

// 获取具体项目的详情
export function listNavDetauls (urlParams) {
	return request({
		url: "/Mobile/details?item_id=" + urlParams,
		method: 'get'
	})
}

//用户投资btn 2-4
export function listNavEnter (data) {
	// item_id amount pwd
	return request({
		url: "/Mobile/form",
		method: 'post',
		data
	})
}

//用户投资2btn 2-4
export function listNavEnter2 (data) {
	// item_id  pwd
	return request({
		url: "/Mobile/form_active",
		method: 'post',
		data
	})
}

// 股权出售
export function guquanSale (data) {
	// num  pwd
	return request({
		url: "/User/guquan_sale",
		method: 'post',
		data
	})
}


//获取股权质押列表
export function getZhiYaList () {
	return request({
		url: "/Mobile/getZhiYaList",
		method: 'get'
	})
}

//申请股权质押
export function postZhiYa (data) {
	// id  num pwd
	return request({
		url: "/User/zhiya",
		method: 'post',
		data
	})
}

//获取个人账户信息
export function getPerson () {
	return request({
		url: "/User/person",
		method: 'get'
	})
}

//获取充值渠道
export function getChargeInfo () {
	return request({
		url: "/Mobile/getChargeInfo",
		method: 'get'
	})
}
//获取充值渠道2
export function getChargeInfo2 () {
	return request({
		url: "/Mobile/getChargeInfo2",
		method: 'get'
	})
}

// 用户充值
export function postRecharge (data) {
	// money id
	return request({
		url: "/User/recharge",
		method: 'post',
		data
	})
}
// 用户充值
export function postRecharge2 (data) {
	// money id
	return request({
		url: "/User/recharge2",
		method: 'post',
		data
	})
}


// 获取用户银行卡
export function getUserBank () {
	return request({
		url: "User/getUserBank",
		method: 'get'
	})
}

//添加用户银行卡
export function postAddUserBank (data) {
	// bank_name account account_name
	return request({
		url: "User/add_card",
		method: 'post',
		data
	})
}

// 删除银行卡
export function delUserBank (data) {
	// bank_id
	return request({
		url: "User/del_card",
		method: 'post',
		data
	})
}

// 用户提现
export function postCash (data) {
	// type(1:收益提现 2:团队分红提现)  pwd(资金密码) money bank_id(银行卡ID)
	return request({
		url: "User/cash",
		method: 'post',
		data
	})
}


// 用户数字划转
export function postTrans (data) {
	// type(666:收益划转 777:团队分红划转)  money(划转的金额)  phone(对方账户)
	return request({
		url: "User/trans",
		method: 'post',
		data
	})
}
// 用户数字划转
export function postTgTrans () {
	// type(666:收益划转 777:团队分红划转)  money(划转的金额)  phone(对方账户)
	return request({
		url: "User/tg_trans",
		method: 'post',
		data: { token: getCookie('token') }
	})
}

//登录密码设置
export function postPwdLogin (data) {
	// pwd(新密码)  pwd2(重复新密码)
	return request({
		url: "User/pwd_login",
		method: 'post',
		data
	})
}

//资金密码设置
export function postPwdPay (data) {
	// pwd(新密码)  pwd2(重复新密码)
	return request({
		url: "User/pwd_pay",
		method: 'post',
		data
	})
}

// 实名认证
export function postCertification (data) {
	// name idcard
	return request({
		url: "User/certification",
		method: 'post',
		data
	})
}



//获取资金明细
export function getFund (data) {
	// pages(当前页数 最小1) num(每页多少条) start(开始时间) end(结束时间) type(1:资金余额  2离岸资产 3封关红利 4封关股权 5团队收益 6 离岸收益)
	return request({
		url: "User/fund",
		method: 'post',
		data
	})
}

// 获取投资记录
export function getInvest (data) {
	// pages num
	return request({
		url: "User/invest",
		method: 'post',
		data
	})
}

//股权质押记录
export function getZhiyaRecord (data) {
	// pages num
	return request({
		url: "User/zhiyaRecord",
		method: 'post',
		data
	})
}

// 获取用户充值记录
export function getRechargeRecord (data) {
	// pages num
	return request({
		url: "User/recharge_record",
		method: 'post',
		data
	})
}

// 获取用户提现记录
export function getCashRecord (data) {
	// pages num
	return request({
		url: "User/cash_record",
		method: 'post',
		data
	})
}

// 获取用户团队信息
export function getDetailTeam (data) {
	// level(非必传  0：所有层级的下级数据，1，一级数据，2:2级数据 3：三级数据) pages(当前页数) num(一页多少条)
	return request({
		url: "User/detail_team",
		method: 'post',
		data
	})
}

// 邀请好友
export function getRecommend () {
	// pages num
	return request({
		url: "User/recommend",
		method: 'get'
	})
}

// app更新
export function getAppVersion (data) {
	// version
	return request({
		url: "/Mobile/getAppVersion",
		method: 'post',
		data
	})
}

// 获取头部list
export function getHeadListNav () {
	// version
	return request({
		url: "/Mobile/list_nav",
		method: 'get'
	})
}

// 获取红利
export function getHongLi () {
	// version
	return request({
		url: "/User/prize",
		method: 'post',
		data: { token: getCookie('token') }

	})
}

// 离岸资产-->离岸账户
export function postTranIn () {
	// version
	return request({
		url: "/User/ln_trans_in",
		method: 'post',
		data: { token: getCookie('token') }

	})
}

// 离岸账户-->内地银行卡账户
export function postTranOut () {
	// version
	return request({
		url: "/User/ln_trans_out",
		method: 'post',
		data: { token: getCookie('token') }

	})
}