import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import { showToast } from 'vant'
import { getCookie,removeCookie } from '@/utils/auth'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 10000, // request timeout
	headers: {'Content-Type': 'application/x-www-form-urlencoded'}

})


// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent

		if (getCookie('token')) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['token'] = getCookie('token')
		}
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	*/

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		
		const res = response.data
		// 非2000为返回，有错误
		if (res.code != '200') {
			showToast(res.msg)
			//如果未登录
			if (res.code == "001") {
				// to re-login
				 location.href ="/"
				 removeCookie("token")
			}
			// console.log(res)
			return Promise.reject(res.msg)
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // 服务器错误
		showToast(error.message)
		return Promise.reject(error)
	}
)

export default service