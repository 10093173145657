import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'

export function getCookie (key) {
	return localStorage[key]
}
//time { maxAge: 24 * 60 * 60 }
export function setCookie (key, token, time) {
	time = time || new Date(new Date().getTime() + 30 * 60 * 1000)
	localStorage.setItem(key, token, time)
}

export function removeCookie (key) {
	delete localStorage[key]
}