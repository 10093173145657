import Vuex from 'vuex'
import { getPerson, getHeadListNav } from '@/api'
import { removeCookie } from "@/utils/auth"

const store = new Vuex.Store({
	state: {
		userInfo: {},
		headIndex: 1,
		headItems: []
	},

	mutations: {
		["SET_USER_INFO"] (state, info) {
			state.userInfo = info
		},
		["SET_HEAD_INDEX"] (state, value) {
			state.headIndex = value
		},
		["SET_HEAD_ITEMS"] (state, value) {
			state.headItems = value
		}
	},
	actions: {
		getUserInfo ({ commit }) {
			return new Promise(resolve => {
				getPerson().then(res => {
					commit("SET_USER_INFO", res.data)
					resolve()
				})
			})
		},
		setHeadIndex ({ commit }, value) {
			commit("SET_HEAD_INDEX", value)
		},
		goOut ({ commit }) {
			commit("SET_USER_INFO", {})
			removeCookie("token")
		},
		getHeadItems ({ commit }) {
			return new Promise(resolve => {
				getHeadListNav().then(res => {
					commit("SET_HEAD_ITEMS", res.data)
					resolve()
				})
			})
		}
	}

})
export default store